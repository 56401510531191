var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full flex flex-col"},[_c('Card',{staticClass:"w-full py-3 px-4 rounded-md flex gap-5 my-5"},[_c('div',{staticClass:"w-full flex justify-between"},[_c('div',{staticClass:"flex w-full flex-grow"},[_c('div',{staticClass:"flex w-full gap-3"},[(_vm.employmentDetails.photo)?_c('img',{staticClass:"w-32 h-44",attrs:{"src":_vm.employmentDetails.photo}}):_c('div',{staticClass:"w-32 h-44 flex flex-col text-lg justify-center text-center font-semibold border rounded-md"},[_vm._v(" "+_vm._s(_vm.$getInitials( `${_vm.employmentDetails.fname} ${_vm.employmentDetails.lname}` ))+" ")]),_c('div',{staticClass:"w-full flex flex-col gap-1"},[_c('div',{staticClass:"w-full flex gap-8 mb-2"},[_c('div',{staticClass:"flex items-start"},[_c('p',{staticClass:"text-lg font-bold"},[_vm._v(" "+_vm._s(_vm.employmentDetails.fname)+" "+_vm._s(_vm.employmentDetails.lname)+" ")])]),_c('div',{staticClass:"flex items-start mx-3"},[_c('div',{class:{
                    pending:
                      _vm.employmentDetails.employeeConfirmationStatus ===
                      'pending',
                    approved:
                      _vm.employmentDetails.employeeConfirmationStatus ===
                        'approved' ||
                      _vm.employmentDetails.employeeConfirmationStatus ===
                        'extended',
                    disapproved:
                      _vm.employmentDetails.employeeConfirmationStatus ===
                        'disapproved' ||
                      _vm.employmentDetails.employeeConfirmationStatus ===
                        'terminated',
                  },staticStyle:{"width":"100px","padding":"5px"}},[_c('div',{staticClass:"w-auto flex flex-col"},[_c('p',{staticClass:"text-xs font-semibold"},[_vm._v(" "+_vm._s(_vm.employmentDetails.employeeConfirmationStatus ? _vm.Capitalize( _vm.employmentDetails.employeeConfirmationStatus ) : "------")+" ")])])])])]),_vm._l((_vm.employeeOptions),function(option,index){return _c('div',{key:index,staticClass:"w-full flex"},[_c('div',{staticClass:"w-1/5 flex items-start"},[_c('p',{staticClass:"text-sm font-semibold text-romanSilver"},[_vm._v(" "+_vm._s(option.title)+": ")])]),_c('div',{staticClass:"w-4/5 flex items-start"},[_c('p',{staticClass:"text-sm font-semibold"},[_vm._v(" "+_vm._s(option.value)+" ")])])])})],2)])]),_c('div',{staticClass:"flex flex-col gap-3"},[_c('Button',{staticClass:"font-semibold text-dynamicBackBtn btn-border",on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Cancel Confirmation ")]),_c('div',{staticClass:"flex flex-col gap-1"},[_c('p',{staticClass:"font-black text-xs text-right text-romanSilver uppercase"},[_vm._v(" Confirmation Due Date: ")]),_c('p',{staticClass:"text-sm font-semibold text-right"},[_vm._v(" "+_vm._s(_vm.employmentDetails.confirmationDueDate ? _vm.$DATEFORMAT( new Date(_vm.employmentDetails.confirmationDueDate), "MMM dd, yyyy" ) : "---")+" ")])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }