<template>
  <div class="w-full h-full flex flex-col">
    <Card class="w-full py-3 px-4 rounded-md flex gap-5 my-5">
      <div class="w-full flex justify-between">
        <div class="flex w-full flex-grow">
          <div class="flex w-full gap-3">
          <img :src="employmentDetails.photo" class="w-32 h-44" v-if="employmentDetails.photo" />
            <div
              class="w-32 h-44 flex flex-col text-lg justify-center text-center font-semibold border rounded-md"
              v-else
            >
              {{
                $getInitials(
                  `${employmentDetails.fname} ${employmentDetails.lname}`
                )
              }}
            </div>

            <div class="w-full flex flex-col gap-1">
              <div class="w-full flex gap-8 mb-2">
                <div class="flex items-start">
                  <p class="text-lg font-bold">
                    {{ employmentDetails.fname }} {{ employmentDetails.lname }}
                  </p>
                </div>

                <div class="flex items-start mx-3">
                  <div
                    style="width: 100px; padding: 5px"
                    :class="{
                      pending:
                        employmentDetails.employeeConfirmationStatus ===
                        'pending',
                      approved:
                        employmentDetails.employeeConfirmationStatus ===
                          'approved' ||
                        employmentDetails.employeeConfirmationStatus ===
                          'extended',
                      disapproved:
                        employmentDetails.employeeConfirmationStatus ===
                          'disapproved' ||
                        employmentDetails.employeeConfirmationStatus ===
                          'terminated',
                    }"
                  >
                    <div class="w-auto flex flex-col">
                      <p class="text-xs font-semibold">
                        {{
                          employmentDetails.employeeConfirmationStatus
                            ? Capitalize(
                                employmentDetails.employeeConfirmationStatus
                              )
                            : "------"
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="w-full flex"
                v-for="(option, index) in employeeOptions"
                :key="index"
              >
                <div class="w-1/5 flex items-start">
                  <p class="text-sm font-semibold text-romanSilver">
                    {{ option.title }}:
                  </p>
                </div>
                <div class="w-4/5 flex items-start">
                  <p class="text-sm font-semibold">
                    {{ option.value }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-3">
          <Button
            class="font-semibold text-dynamicBackBtn btn-border"
            @click="$router.back()"
          >
            Cancel Confirmation
          </Button>
          <div class="flex flex-col gap-1">
            <p class="font-black text-xs text-right text-romanSilver uppercase">
              Confirmation Due Date:
            </p>
            <p class="text-sm font-semibold text-right">
              {{
                employmentDetails.confirmationDueDate
                  ? $DATEFORMAT(
                      new Date(employmentDetails.confirmationDueDate),
                      "MMM dd, yyyy"
                    )
                  : "---"
              }}
            </p>
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
export default {
  name: "ConfirmationEmploymentDetails",
  components: {
    Card: () => import("@/components/Card"),
    Button: () => import("@/components/Button"),
  },
  props: {
    employmentDetails: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      employeeOptions: [
        {
          title: "Function",
          value: this.employmentDetails.orgFunction?.name,
        },
        {
          title: "Designation",
          value: this.employmentDetails.userDesignation?.name,
        },
        {
          title: "Job Level",
          value: this.employmentDetails.userLevel?.name,
        },
        {
          title: "Office Location",
          value: this.employmentDetails.employeeOffice?.name,
        },
        {
          title: "Manager",
          value: `${this.employmentDetails.userReportingTo?.fname} ${this.employmentDetails.userReportingTo?.lname}`,
        },
        {
          title: "Length of Service",
          value: `${this.getLengthService()}`,
        },
      ],
    };
  },
  methods: {
    getLengthService() {
      const today = new Date();

      const targetDate = new Date(this.employmentDetails.resumptionDate);

      const differenceInMs = today - targetDate;

      const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

      const years = Math.floor(differenceInDays / 365);
      const months = Math.floor((differenceInDays % 365) / 30);
      const days = Math.floor((differenceInDays % 365) % 30);

      if (years) {
        return `${years} Yr(s) ${months} Months`
      }
      if (months) {
        return `${months} Months`
      }
      if (days) {
        return `${days} days`
      }
      return "No length of service"
    },
  },
};
</script>

<style scoped>
.btn-border {
    border: 1px solid var(--dynamic-Background) !important;
 }
</style>
